<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import LayoutPC from "@/layout/pc";
import LayoutMobile from "@/layout/mobile";
import { isMobile } from "@/utils";
export default {
  components: {
    LayoutPC,
    LayoutMobile,
  },
  computed: {
    layout() {
      if (isMobile()) {
        return LayoutMobile;
      } else {
        return LayoutPC;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
