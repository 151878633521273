<template>
  <div class="main-container">
    <header class="header-wrapper">
      <div class="header_top">
        <svg-icon icon-class="logo_艾佳德" width="46%" height="90%" />
      </div>
      <div class="nav">
        <div
          v-for="item in navList"
          :key="item.index"
          @click="handleToLink(item)"
          class="nav_item"
          :class="{
            visited: item.path === $route.path,
          }"
        >
          {{ item.name }}
        </div>
      </div>
    </header>
    <slot />
    <footer class="footer">
      <div class="footer-top">
        <span>承办单位：北京艾佳德科技发展有限公司</span>
        <span>主办单位：中国心理卫生协会</span>
      </div>
      <div class="footer-bottom">
        <span>联系我们</span>
        <span>联系电话：13261637765</span>
        <span>心理咨询师专业技能培训项目中心</span>
        <svg-icon icon-class="底部导航_logo" width="80px" height="80px" />
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "课程学习",
          path: "http://xinlizixun.aijiad.com",
        },
        {
          name: "证书查询",
          path: "/certificateQuery",
        },
        // {
        //   name: "证书查询",
        //   path: "https://www.aijiad.com/query_score/#/",
        // },
        {
          name: "联合机构",
          path: "/authorized",
        },
        {
          name: "公告",
          path: "/announce",
        },
        {
          name: "APP下载",
          path: "http://xinlizixun.aijiad.com/downloadApp",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      // console.log(7777777777777, to.path, from.path);
    },
  },
  mounted() {},
  methods: {
    handleToLink(item) {
      if (item.path) {
        let reg =
          /^(https?:\/\/)(www\.aijiad\.com\/query_score\/#\/|xinlizixun\.aijiad\.com(\/downloadApp)?)$/;
        if (reg.test(item.path)) {
          window.open(item.path);
          return;
        }
        if (this.$route.path !== item.path) {
          this.$router.push({
            path: item.path,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$vw_base: 390;
$vh_base: 750;
@function vw($px) {
  //这个是移动端适配用到的
  @return ($px / $vw_base) * 100vw;
}
@function vh($px) {
  //这个是移动端适配用到的
  @return ($px / $vh_base) * 100vh;
}
.main-container {
  background-color: #f0f2f5;
  .header-wrapper {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // height: vh(80);
    background-color: #282828;
    color: aliceblue;
    // padding: 20px 0 0;
    .header_top {
      height: vh(60);
      padding: vw(10) 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .nav {
      display: flex;
      justify-content: space-between;
      // padding: 0 vw(24);
      background-color: rgba(76, 76, 76, 0.9);
      .visited {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(185, 136, 98, 1);
      }
      .nav_item {
        min-width: 16%;
        font-size: vw(14);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: vh(5) 0;
      }
    }
    // .nav {
    //   position: relative;
    //   ul {
    //     display: none;
    //     position: absolute;
    //     top: 22px;
    //     right: 0px;
    //     background: rgba(255, 255, 255, 1);
    //     padding-inline-start: 0px;
    //     color: rgba(40, 40, 40, 1);
    //     border-radius: 4px;
    //   }
    //   ul > li {
    //     list-style: none;
    //     width: 120px;
    //     text-align: center;
    //     font-size: 14px;
    //     font-weight: 400;
    //     padding: 10px 15px;
    //   }
    //   .visited {
    //     color: rgba(255, 255, 255, 1);
    //     background-color: rgba(185, 136, 98, 1);
    //   }
    //   .first {
    //     border-radius: 4px 4px 0 0;
    //   }
    //   .last {
    //     border-radius: 0 0 4px 4px;
    //   }
    // }
  }
  .footer {
    padding: 30px 24px;
    // height: 402px;
    background-color: #ffffff;
    font-size: vw(16);
    font-weight: 500;
    color: #929797;
    .footer-top {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      // align-items: center;
      padding-bottom: 20px;
      border-bottom: 2px solid #282828;
    }
    .footer-bottom {
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
