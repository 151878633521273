<template>
  <div class="query-container">
    <header-bar>
      <img
        src="~@/assets/img/切图_web_0628/logo-02.png"
        width="49px"
        height="49px"
      />
      <span class="header-title">艾佳德</span>
    </header-bar>
    <nav-bar />
    <div class="notice">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">通知公告</span>
          <!-- <span class="title-cn">NOTICE NOTICE</span> -->
        </div>
        <div class="content">
          <ul class="content-ul">
            <li
              v-for="item in notice"
              :key="item.title"
              @click="showNotice(item)"
            >
              <div class="title">{{ item.title }}</div>
              <div class="date">{{ item.at }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="notice_dialog.visible"
      :title="notice_dialog.title"
    >
      <div class="img-box-notice">
        <img :src="notice_dialog.pic" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderBar from "../components/header.vue";
import NavBar from "../components/nav.vue";
import { noticeList } from "../data";
import axios from "axios";

export default {
  components: {
    HeaderBar,
    NavBar,
  },
  data() {
    return {
      notice: [],
      notice_dialog: {
        visible: false,
        title: "",
        pic: "",
      },
    };
  },
  mounted() {
    this.getInfo();
    let docu = document.getElementsByClassName("notice")[0];
    docu.style.height = window.innerHeight - 100 - 60 - 274 + "px";
  },
  methods: {
    getInfo() {
      axios
        .get("/v1/system/announcements/", {
          params: {},
        })
        .then((response) => {
          this.notice = response.data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    showNotice(item) {
      this.notice_dialog.title = item.title;
      this.notice_dialog.pic = this.extractImageUrl(item.content);
      this.notice_dialog.visible = true;
    },
    // 解析 Markdown 字符串的图片路径
    extractImageUrl(markdown) {
      const regex = /!\[.*?\]\((.*?)\)/;
      const match = markdown.match(regex);
      return match ? match[1] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.query-container {
  .header-title {
    font-size: 34px;
    color: #fff;
    font-weight: 500;
    margin-left: 5px;
  }
  .notice {
    width: 100%;
    background: #fff;

    .content {
      margin-top: 30px;
      background: #f5f5f5;
      padding: 30px 60px;

      .content-ul {
        li + li {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
        li {
          height: 49px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .title {
            color: rgba(0, 0, 0, 1);
            font-size: 16px;
          }
        }
      }
    }
  }
  .publicTitle {
    font-size: 28px;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
    text-align: center;
    .title-zh {
      // margin-left: 112px;
    }
    // .title-cn {
    //   margin-left: 10px;
    // }
  }
}
.img-box-notice {
  text-align: center;
  height: 450px;
  overflow-y: scroll;

  img {
    width: 542px;
    height: auto;
  }
}
</style>
