import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import Swiper from 'swiper'

import {
  Dialog,
  Input,
  Button,
  Image,
  Form,
  FormItem,
  MessageBox,
  Row,
  Col,
  Divider,
  Message,
} from "element-ui";
import "./styles/index.css";
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Button);
Vue.use(Image);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Divider);
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;

import "./icons"; // icon
import "./styles/index.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
