<template>
  <div class="authorized">
    <div class="banner">
      <p class="zh">心理咨询师专业技能培训项目</p>
      <p></p>
      <p></p>
      <p></p>
      <span class="line"></span>
      <p></p>
      <p></p>
      <p></p>
      <p class="zh">联合招生机构</p>
      <span class="line"></span>
    </div>
    <div class="search">
      <el-input placeholder="请输入查询机构" clearable v-model="query">
      </el-input>
      <el-button :type="buttonType" @click="handleSearch()">查询</el-button>
      <div class="company">
        <div v-for="item in companyList" :key="item.index" class="company-item">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      query: "",
      buttonType: "info",
      companyList: [],
    };
  },
  watch: {
    query() {
      if (this.query.length > 0) {
        this.buttonType = "warning";
      } else {
        this.buttonType = "info";
      }
    },
  },
  methods: {
    handleSearch() {
      if (this.query !== "") {
        axios
          .get("/v1/account/institutions/", {
            params: {
              keyword: this.query,
              // type: "2",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.companyList = response.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.companyList = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.authorized {
  .banner {
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 45px;
    color: #fff;
    background: url("~@/assets/img/mb_img/授权机构bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .zh {
      font-size: 20px;
      font-weight: 600;
    }
    .en {
      font-size: 16px;
      font-weight: 400;
    }
    .line {
      width: 20px;
      border: 1px solid;
    }
  }
  .search {
    padding: 30px 24px;
    .company {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      .company-item {
        width: 90%;
        padding: 15px 0;
        text-align: center;
        margin-bottom: 15px;
        border-radius: 5px;
        background-color: #f5f5f5;
      }
    }
  }
}
</style>
<style lang="scss">
.authorized {
  .el-button {
    width: 100%;
    margin: 15px 0;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #e6a23c;
  }
}
</style>
