<template>
  <div class="homepage">
    <div class="banner">
      <div>
        <img
          :src="bannerConfig.iconImg"
          class="logo-img"
          width="70px"
          height="70px"
          alt=""
        />
      </div>
      <p class="above">{{ bannerConfig.title }}</p>
      <div class="desc">
        <div class="one" v-html="bannerConfig.description.one"></div>
      </div>
    </div>
    <div class="course">
      <div
        class="item"
        v-for="item in courseList"
        :key="item.index"
        @click="courseClick(item)"
        :style="{ backgroundImage: `url(${item.bgImg})` }"
      >
        <div class="item-title">
          <span>{{ item.title }}</span>
          <svg-icon icon-class="butten_right" width="18px" height="13px" />
        </div>
      </div>
    </div>
    <div class="teachers">
      <div class="publicTitle">
        <p class="zh">师资介绍</p>
        <!-- <p class="en">TEACHERS</p> -->
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in teacherList"
            :key="index"
          >
            <div
              class="left"
              :style="{ backgroundImage: `url(${item.pic})` }"
            ></div>
            <div class="right">
              <div class="name_title">
                <div class="name-zh">
                  {{ item.name_zh }}
                </div>
                <!-- <div class="name-cn">
                  {{ item.name_cn }}
                </div> -->
                <div class="line"></div>
              </div>
              <div
                class="info-title"
                v-for="title in item.titles"
                :key="title"
                v-html="title"
              ></div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import { courseList } from "./data";
import axios from "axios";
export default {
  data() {
    return {
      bannerConfig: {
        iconImg: "",
        title: "",
        description: {
          one: "",
        },
      },
      teacherList: [],
      swiperInstance: null,
      courseList: courseList,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      const data_belong = "homeData";
      axios
        .get(`/v1/system/official_website/${data_belong}/`, {})
        .then((response) => {
          if (response.status === 200) {
            this.bannerConfig = {
              // backgroundImage: backgroundImage,
              iconImg: response.data.logo,
              title: response.data.title,
              description: {
                one: response.data.description,
              },
            };
            let teacher = response.data.teacherIntroduces.map((item) => {
              return {
                name_zh: item.name,
                pic: item.avatar,
                titles: item.honorCertificate,
              };
            });
            this.teacherList = teacher;
            this.$nextTick(() => {
              this.initSwiper();
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    initSwiper() {
      if (this.swiperInstance) {
        this.swiperInstance.destroy(true, true);
      }
      this.swiperInstance = new Swiper(".swiper-container", {
        //direction: 'vertical', // 垂直切换选项
        //mousewheel: true, //滚轮
        loop: true, // 循环模式选项
        autoplay: {
          delay: 5000,
        },
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
      });
    },
    courseClick(item) {
      if (item.path) {
        this.$router.push({
          path: item.path,
        });
      } else {
        window.open(item.url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$vw_base: 390;
// $vh_base: 1080;
@function vw($px) {
  //这个是移动端适配用到的
  @return ($px / $vw_base) * 100vw;
}
.homepage {
  .banner {
    height: 400px;
    background: url("~@/assets/img/mb_img/bg_关于艾佳德.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 24px;
    color: #fff;
    .logo-img {
      border-radius: 100%;
    }
    .above {
      font-size: 24px;
      font-weight: 600;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: rgba(255, 255, 255, 0.7);
      .one {
        margin-bottom: 20px;
      }
    }
  }
  .course {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // height: 390px;
    padding: 30px 24px;
    background-color: #fff;
    .item + .item {
      margin-top: 10px;
    }
    .item {
      width: 100%;
      height: 98px;
      display: flex;
      align-items: flex-end;
      .item-title {
        height: 40px;
        width: 100%;
        background-image: linear-gradient(
          to right,
          rgba(168, 126, 100, 1),
          rgba(53, 67, 105, 1)
        );
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .teachers {
    // height: 325px;
    padding: 15px 24px;
    background-color: #f5f5f5;
    .swiper-container {
      height: 250px;
      width: 100%;
      .swiper-slide + .swiper-slide {
        // padding-left: 5px;
      }
      .swiper-slide {
        height: vw(200);
        display: flex;

        .left {
          flex-basis: 44%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .right {
          flex: 1;
          background: rgba(185, 136, 98, 1);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding: 0 vw(5);
          .name_title {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: vw(5);
            .name-zh {
              font-size: vw(18);
            }
            .name-cn {
              font-size: vw(15);
              margin-top: 4px;
            }
            .line {
              width: 30px;
              height: 1px;
              background: #fff;
              margin: 5px 0;
            }
          }
          .info-title {
            font-size: vw(12);
            line-height: vw(18);
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
  .publicTitle {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #282828;
    margin-bottom: 15px;
    p {
      margin: 0;
      padding: 0;
    }
    .zh {
      font-size: 24px;
      font-weight: 600;
    }
    .en {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
