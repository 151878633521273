<template>
  <div>
    <div class="notice">
      <div class="publicTitle">
        <p class="zh">通知公告</p>
        <!-- <p class="en">NOTICE NOTICE</p> -->
      </div>
      <div class="content">
        <div
          v-for="item in noticeList"
          :key="item.index"
          @click="checkNotice(item)"
          class="notice-item"
          :class="
            noticeList.indexOf(item) === noticeList.length - 1
              ? ''
              : 'underline'
          "
        >
          <span>{{ item.title }}</span>
          <span>{{ item.at }}</span>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="notice_dialog.visible"
      :title="notice_dialog.title"
      width="90%"
    >
      <div class="img-box-notice">
        <el-image
          :src="`${notice_dialog.pic}`"
          :preview-src-list="[`${notice_dialog.pic}`]"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { noticeList } from "./data";
import axios from "axios";
export default {
  data() {
    return {
      noticeList: [],
      notice_dialog: {
        visible: false,
        title: "",
        pic: "",
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      axios
        .get("/v1/system/announcements/", {
          params: {},
        })
        .then((response) => {
          this.noticeList = response.data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    checkNotice(item) {
      this.notice_dialog.title = item.name;
      this.notice_dialog.pic = this.extractImageUrl(item.content);
      this.notice_dialog.visible = true;
    },
    // 解析 Markdown 字符串的图片路径
    extractImageUrl(markdown) {
      const regex = /!\[.*?\]\((.*?)\)/;
      const match = markdown.match(regex);
      return match ? match[1] : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.notice {
  padding: 15px 24px;
  .content {
    background-color: #f5f5f5;
    padding: 30px 15px;
    .notice-item {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
    .underline {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    }
  }
}
.publicTitle {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #282828;
  margin-bottom: 15px;
  p {
    margin: 0;
    padding: 0;
  }
  .zh {
    font-size: 24px;
    font-weight: 600;
  }
  .en {
    font-size: 16px;
    font-weight: 400;
  }
}
.img-box-notice {
  text-align: center;
  height: 450px;
  overflow-y: scroll;

  img {
    width: 542px;
    height: auto;
  }
}
</style>
