<template>
  <div class="psyc-container">
    <header-bar>
      <svg-icon
        @click="$router.back(-1)"
        icon-class="back"
        width="30px"
        height="30px"
      />
      <span class="header-title">家庭教育指导师培训 </span>
    </header-bar>
    <nav class="nav-wrapper">
      <ul>
        <li
          v-for="item in nav_list"
          :key="item.label"
          :class="{ 'is-active': item.label === activeTab }"
          @click="handleScroll(item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </nav>
    <Banner
      :backgroundImage="bannerConfig.backgroundImage"
      :iconClass="bannerConfig.iconClass"
      :title="bannerConfig.title"
      :iconImg="bannerConfig.iconImg"
      :desc="bannerConfig.desc"
    />

    <div class="policies">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">相关政策</span>
          <!-- <span class="title-cn">RELEVANT POLICIE</span> -->
        </div>

        <div class="content">
          <div class="left">
            <img
              src="~@/assets/img/切图_web_0628/家庭_相关政策.png"
              width="465px"
              height="412px"
              alt=""
            />
          </div>
          <div class="right">
            <div class="top">
              <div class="desc">
                <div>妇字〔2010〕6号</div>
                <div>关于印发《全国家庭教育指导大纲》的通知</div>
                <div>
                  各省、自治区、直辖市妇联，教育厅（教委），文明办，民政厅，卫生厅，人口计生委，关工委：
                </div>

                <div>
                  为了深入贯彻落实《中共中央国务院关于进一步加强和改进未成年人思想道德建设的若干意见》和全国未成年人思想道德建设经验交流会议精神，进一步加强家庭教育理论体系建设，规范家庭教育指导内容和要求，提高家庭教育的科学性、针对性、实效性，全国妇联与教育部、中央文明办、民政部、卫生部、国家人口计生委、中国关工委联合印发《全国家庭教育指导大纲》
                </div>
                <div :style="{ marginTop: '30px' }">全 国 妇 联 教 育 部</div>
                <div>中 央 文 明 办 民 政 部</div>
                <div>卫生部国家人口计生委</div>
                <div>中国关工委</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="outline">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">课程大纲</span>
          <!-- <span class="title-cn">CURRICULUM SYLLABUS</span> -->
        </div>
        <div class="content">
          <div class="item" v-for="item in outlineList" :key="item.textZH">
            <div class="top">
              <div class="text-zh">{{ item.textZH }}</div>
              <!-- <div class="text-cn">{{ item.textCN }}</div> -->
            </div>
            <div class="bottom">
              <div class="text" v-for="item in item.list" :key="item">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="teacher">
      <div class="bm-content-container">
        <div class="top">
          <div class="publicTitle">
            <span class="title-zh">主讲老师</span>
            <!-- <span class="title-cn">LECTURER</span> -->
          </div>
          <div class="swiper-pagination"></div>
        </div>

        <div class="content">
          <div class="swiper-container">
            <!-- 如果需要分页器 -->

            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="teacher-item"
                  v-for="(child, childIndex) in item"
                  :key="childIndex"
                >
                  <img :src="child.pic" width="240px" height="300px" alt="" />
                  <div class="name-zh">{{ child.name_zh }}</div>
                  <!-- <div class="name-cn">{{ child.name_cn }}</div> -->

                  <div class="line"></div>
                  <div
                    class="title"
                    v-for="title in child.titles"
                    :key="title"
                    v-html="title"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="train">
      <div class="bm-content-container">
        <div class="left">
          <img
            src="~@/assets/img/bg_考核与证书.png"
            width="270px"
            height="283px"
            alt=""
          />
          <img
            class="img-two"
            src="~@/assets/img/bg_线上精品课程.png"
            width="300px"
            height="283px"
            alt=""
          />
        </div>
        <div class="right">
          <div class="publicTitle">
            <span class="title-zh">培训方式</span>
            <!-- <span class="title-cn">TRAINING METHODS</span> -->
          </div>

          <div class="button">线上学习</div>
          <div class="desc">
            线上课程培训，可在app、小程序、电脑端进行学习。
          </div>
        </div>
      </div>
    </div>

    <div class="assessment">
      <div class="bm-content-container">
        <div class="publicTitle">
          <span class="title-zh">考核与证书</span>
          <!-- <span class="title-cn">ASSESSMENT AND CERTIFICATE</span> -->
        </div>
        <div class="content">
          <div class="left">
            <div class="item">
              <span class="line"></span>
              <span class="title">考试形式</span>

              <div class="desc">线上考试，每年三次</div>
            </div>

            <div class="item">
              <span class="line"></span>
              <span class="title">考试时间</span>

              <div class="desc">每年3、7、12月</div>
            </div>

            <div class="item">
              <span class="line"></span>
              <span class="title">考试内容</span>

              <div class="desc">学员完成课程学习后参加线上综合考试</div>
              <!-- <div class="desc">考核：综合考试及论文撰写</div> -->
              <!-- <div class="desc">
                理论和操作技能：线上考试，学员完成全部课程学习可方有资格参加
              </div>
              <div class="desc">
                论文撰写：由学员提供个人成长报告（2000字以上）
              </div> -->
            </div>
          </div>
          <div class="right">
            <div class="title">证书样本</div>
            <!-- <img
              src="~@/assets/img/mb_img/家庭教育证书.png"
              width="216.52px"
              height="153px"
              alt=""
            /> -->
            <el-image
              :src="`${assessmentUrl}`"
              :preview-src-list="[`${assessmentUrl}`]"
            />

            <div class="desc">
              证书由中国心理卫生协会颁发，统一编号，在项目授权网站
              www.aijiad.com
              进行查询。此证书表明持证人已经通过相关课程培训与考核，具备相应的专业知识能力、可作为能力评价、考核、用人单位聘用的参考依据。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="price">
      <div class="bm-content-container">
        <div class="price-item">
          课程指导价
          <span>3980</span>
          元/人。
        </div>
        <div class="price-desc">
          包含培训课程费。考试考务管理费，证书工本费。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import HeaderBar from "@/views/pc/components/header.vue";
import NavBar from "@/views/pc/components/nav.vue";
import Banner from "@/views/pc/components/banner.vue";
import { multList } from "@/utils";

import iconImg from "@/assets/img/切图_web_0628/关于aijiade.png";
import assessmentUrl from "@/assets/img/mb_img/家庭教育证书.png";
import backgroundImage from "@/assets/img/bg_家庭教育.png";
import axios from "axios";

import { qaList, homeTeacher } from "../data";
export default {
  components: {
    HeaderBar,
    NavBar,
    Banner,
  },
  data() {
    return {
      list: [],
      swiperInstance: null,
      activeTab: "项目介绍",
      nav_list: [
        { label: "项目介绍", scrollTop: 0 },
        { label: "相关政策", scrollTop: 400 },
        { label: "课程大纲", scrollTop: 1000 },
        { label: "主讲老师", scrollTop: 1471 },
        { label: "培训方式", scrollTop: 2356 },
        { label: "考核与证书", scrollTop: 2789 },
      ],
      bannerConfig: {
        backgroundImage: backgroundImage,
        iconImg: iconImg,
        title: "中国心理卫生协会家庭教育指导师专业技能培训",
        desc: {
          one: "百年树人，家教为先。家庭教育是个体教育的起点，是学校教育与社会教育的重要基础。家庭教育的质量直接关系到儿童青少年的健康成长乃至中华民族的全面复兴。",
          two: "为了深入贯彻《家庭教育促进法》，促进我国社会心理服务体系建设，中国心理卫生协会继续教育中心发挥专业和专家优势，组织开发并推出“家庭教育指导师专业技能”培训课程，满足广大心理学爱好者学习家庭教育知识和专业技能的需要。",
        },
      },
      assessmentUrl: assessmentUrl,
      questionList: qaList,
      outlineList: [
        {
          textZH: "家庭教育指导概述",
          textCN: "OVERVIEW FAMILY EDUCATION",
          list: [
            "家庭教育相关政策与法律法规",
            "家庭教育基础知识",
            "家庭教育实操技能",
          ],
        },
        {
          textZH: "家庭教育相关知识 和基本理论",
          textCN: "BASIC THEORY",
          list: ["家长家庭教育指导", "儿童身心发展规律和家 庭教育科学方法"],
        },
        {
          textZH: "家庭教育指导实践 与工作实务",
          textCN: "GUIDE PRACTICE",
          list: ["家庭教育实践技能", "家庭教育指导工作实务"],
        },
        {
          textZH: "心理评估",
          textCN: "PSYCHOLOGICAL ASSESSMENT",
          list: ["心理评估初级", "心理评估中级"],
        },
      ],
    };
  },
  created() {
    // this.list = multList(homeTeacher, 4);
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      const data_belong = "FEExpertiseData";
      axios
        .get(`/v1/system/official_website/${data_belong}/`, {})
        .then((response) => {
          if (response.status === 200) {
            this.bannerConfig = {
              backgroundImage: backgroundImage,
              iconImg: response.data.logo,
              title: response.data.title,
              desc: {
                one: response.data.description,
                two: "本公司为中国心理卫生协会心理咨询项目的承办单位，我们将凝聚更多心理学专业学者、专家，<br/>陪伴合作机构共同传播心理健康知识。",
              },
            };
            let teacher = response.data.teacherIntroduces.map((item) => {
              return {
                name_zh: item.name,
                pic: item.avatar,
                titles: item.honorCertificate,
              };
            });
            this.list = multList(teacher, 4);
            this.$nextTick(() => {
              this.initSwiper();
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    initSwiper() {
      if (this.swiperInstance) {
        this.swiperInstance.destroy(true, true);
      }
      this.swiperInstance = new Swiper(".swiper-container", {
        //direction: 'vertical', // 垂直切换选项
        //mousewheel: true, //滚轮
        loop: true, // 循环模式选项
        autoplay: {
          delay: 5000,
        },
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
      });
    },
    handleScroll(item) {
      this.activeTab = item.label;

      document.documentElement.scrollTop = item.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.psyc-container {
  .header-wrapper {
    position: fixed;
    top: 0;
    z-index: 9999;
  }
  .header-title {
    font-size: 26px;
    color: #fff;

    .svg-icon {
      cursor: pointer;
    }
  }

  .nav-wrapper {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #4e4c4c;

    position: fixed;
    top: 100px;
    z-index: 9999;

    display: flex;
    justify-content: center;

    ul {
      height: 60px;
      display: flex;
      justify-content: center;

      li {
        width: 200px;
        list-style: none;
        text-align: center;
        color: #fff;
        font-size: 16px;
        cursor: pointer;

        a {
          color: #fff;
        }
      }
      .is-active {
        background: #b98862;
      }
    }
  }

  .banner {
    margin-top: 160px;
  }

  .policies,
  .outline,
  .teacher,
  .train,
  .assessment {
    width: 100%;
    background: rgba(245, 245, 245, 1);
    // .title {
    //   font-size: 26px;
    //   color: rgba(40, 40, 40, 1);

    //   .title-cn {
    //     margin-left: 10px;
    //   }
    // }

    .content {
      margin-top: 30px;
    }
  }

  .assessment {
    height: 553px;
    .content {
      height: 366px;
      margin-top: 30px;

      display: flex;
      justify-content: space-around;

      .left {
        padding: 20px 20px 30px 20px;
        flex-basis: 527px;
        background: rgba(255, 255, 255, 1);

        .item + .item {
          margin-top: 10px;
        }
        .item {
          span {
            vertical-align: middle;
          }

          .line {
            width: 2px;
            height: 18px;
            background: rgba(185, 136, 98, 1);
            display: inline-block;
          }
          .title {
            font-size: 20px;
            font-weight: 600;
            margin-left: 10px;
          }

          .desc {
            margin-top: 10px;
            font-weight: 400;
            line-height: 22px;
            font-size: 16px;
            color: rgba(40, 40, 40, 0.9);
          }
        }
      }

      .right {
        flex-basis: 409px;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        background: url("~@/assets/img/bg_考核与证书.png");

        color: #fff;

        .title {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .desc {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }

  .policies {
    .content {
      display: flex;
      justify-content: space-between;

      .right {
        width: 465px;
        .top {
          height: 412px;
          padding: 20px 30px;
          border-top: 3px solid #b98862;
          background: #ffffff;

          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
          }

          .desc + .desc {
            margin-top: 10px;
          }
        }
        .bottom {
          height: 126px;
          padding: 20px 30px;
          border-top: 3px solid #b98862;
          background: #ffffff;

          margin-top: 20px;
        }
      }
    }
  }

  .outline {
    background: #fff;

    .content {
      display: flex;
      justify-content: space-around;
      .item {
        width: 225px;
        height: 292px;
        box-shadow: 0px 0px 30px 0px rgba(42, 66, 111, 0.1);

        .top {
          width: 100%;
          height: 134px;
          display: flex;
          align-items: center;
          background: #2a426f;
          padding: 30px 10px;

          .text-zh {
            font-size: 25px;
            font-weight: 600;
            color: #ffffff;
            line-height: 28px;
          }
          // .text-cn {
          //   font-size: 16px;
          //   font-weight: 500;
          //   color: #b98862;
          //   line-height: 22px;
          // }
        }
        .bottom {
          padding: 20px 30px;

          .text {
            font-size: 16px;
            font-weight: 400;
            color: #2a426f;
            line-height: 22px;
          }
          .text + .text {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .teacher {
    .bm-content-container {
      padding: 60px 100px;
    }
    .top {
      display: flex;
      justify-content: space-between;

      .swiper-pagination {
        position: relative;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 10px;

      .swiper-container {
        margin-top: 30px;
        height: 550px;

        .swiper-slide {
          display: flex;
          height: 100%;

          .teacher-item + .teacher-item {
            margin-left: 10px;
          }
          .teacher-item {
            width: 240px;
            height: 100%;
            background: #b98862;

            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;

            .name-zh {
              font-size: 20px;
              font-weight: 500;
              color: #ffffff;
              margin-top: 10px;
              // line-height: 28px;
            }

            .name-cn {
              font-size: 16px;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.4);
              // line-height: 22px;

              // position: absolute;
              // top: 305px;
            }

            .line {
              width: 20px;
              height: 1px;
              background: #fff;
              margin-top: 8px;
              margin-bottom: 3px;
            }

            .title {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 27px;
              text-align: center;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }

  .train {
    height: 433px;
    background: #ffffff;

    .bm-content-container {
      display: flex;
      justify-content: space-between;

      .left {
        position: relative;
        .img-two {
          position: absolute;
          left: 20px;
          top: 20px;
        }
      }

      .right {
        flex-basis: 600px;

        .button {
          width: 120px;
          height: 44px;
          background: #b98862;

          font-size: 20px;
          font-weight: 600;
          color: #ffffff;
          line-height: 28px;

          line-height: 44px;
          text-align: center;

          margin-top: 60px;
        }

        .desc {
          font-size: 20px;
          font-weight: 500;
          color: #282828;
          line-height: 28px;

          margin-top: 30px;
        }
      }
    }
  }

  .price {
    width: 100%;
    height: 207px;

    background: #ffffff;

    .bm-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .price-item,
    .price-desc {
      font-size: 26px;
      font-weight: 400;
      color: #282828;
      line-height: 37px;
    }

    .price-item span {
      font-size: 32px;
      color: #b98862;
    }
  }

  .answer {
    padding: 60px 120px;
    background: #f5f5f5;

    .title {
      height: 88px;
      background: url("~@/assets/img/常见问题.png");
      background-size: cover;

      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 88px;
      text-align: center;
    }

    .item {
      margin-top: 30px;

      .item-title {
        font-size: 20px;
        font-weight: 600;
        color: #b98862;
        line-height: 28px;
      }
      .item-desc {
        font-size: 20px;
        font-weight: 500;
        color: #282828;
        line-height: 28px;
      }
    }
  }
  .publicTitle {
    font-size: 28px;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
    text-align: center;
    .title-zh {
      // margin-left: 112px;
    }
    // .title-cn {
    //   margin-left: 10px;
    // }
  }
}
</style>
<style>
.swiper-pagination-bullet {
  display: inline-block;
  margin-right: 5px;
}
</style>
